import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { InputStyle } from '../../shared/form-control/Input';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';
import { MembersFilterValue } from '../../../models/TableViewFilters';
import { FilterProps } from './FilterProps';
import usePermissions from '../../../hooks/permissions/usePermissions';
import { Roles } from '../../../models/Role';
import { Option } from '../../Option';
import Checkbox from '../../shared/form-control/Checkbox';
import UserListRenderer from '../../ownership/UserListRenderer';
import { PeopleType } from '../../../models/Distribution';
import { ImageSize } from '../../ownership/ProfileImageStack';
import SkeletonLoader from '../../shared/skeleton-loader/SkeletonLoader';
import { SearchInput } from '../../shared/form-control/SearchInput';
import DropdownSelect, { DropdownSize } from '../../shared/form-control/DropdownSelect';
import { ClientFormUserRole, ClientFormUserRoleRequiredness, ClientFormUserRoleRequirednessKeys } from '../../../models/ClientFormUserRoles';
import { useTranslation } from 'react-i18next';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';
import { graphql } from 'gql.tada';
import { useTableView } from '../../../contexts/table-view/TableViewContext';
import { useQuery } from '@apollo/client';
import { allTemplatesId, ColumnType, PlatformClientFormUserRoleStatusToGQLStatus, tableViewMetadata } from '../../../models/TableView';
import UsersGroup2Icon from '../../shared/icon/UsersGroup2Icon';
import { useCurrentUser } from '../../../global-state/Auth';

type RequirednessOption = Option<string, (typeof ClientFormUserRoleRequiredness)[keyof typeof ClientFormUserRoleRequiredness]>;

export const ClientFormUsersQuery = graphql(`
  query clientFormUsers($cmId: UUID, $cmsId: UUID, $tfId: UUID, $where: ClientFormUserFilterInput) {
    clientFormUsers(clientModuleId: $cmId, clientModuleSectionId: $cmsId, templateFormId: $tfId, where: $where) {
      pbkUser {
        email
        fullName
        id
        userImageId
      }
      userId
      role
      requiresAction
    }
  }
`);

type UserOption = Option<string, boolean> & { userImageId?: string };

const MembersFilter: FC<FilterProps> = ({ columnConfig, selectedTemplateId }) => {
  const { clientModuleId, clientModuleSectionId, selectedTemplateFormId } = useTableView();
  const currentUser = useCurrentUser((x) => x.value);
  const hasPermission = usePermissions();
  const { filters, setFilters } = useTableViewFilters();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<UserOption[]>([]);
  const [requiresActionMap, setRequiresActionMap] = useState<Record<string, boolean>>({});
  const { t } = useTranslation(['common', 'table-view']);

  const filterValue = useMemo(
    () => filters?.[selectedTemplateId]?.[columnConfig.value]?.filter as MembersFilterValue | undefined,
    [columnConfig.value, filters, selectedTemplateId],
  );

  const requirednessOptions: RequirednessOption[] = useMemo(
    () => [
      {
        id: `${ClientFormUserRoleRequiredness.any}`,
        text: t(ClientFormUserRoleRequirednessKeys[ClientFormUserRoleRequiredness.any]),
        value: ClientFormUserRoleRequiredness.any,
      },
      {
        id: `${ClientFormUserRoleRequiredness.required}`,
        text: t(ClientFormUserRoleRequirednessKeys[ClientFormUserRoleRequiredness.required]),
        value: ClientFormUserRoleRequiredness.required,
      },
      {
        id: `${ClientFormUserRoleRequiredness.optional}`,
        text: t(ClientFormUserRoleRequirednessKeys[ClientFormUserRoleRequiredness.optional]),
        value: ClientFormUserRoleRequiredness.optional,
      },
    ],
    [t],
  );

  const selectedRequiredness = useMemo(() => {
    if (filterValue?.requiredness === null || filterValue?.requiredness === undefined) {
      return requirednessOptions[0]; // Default to 'all'
    }

    return (
      requirednessOptions.find(
        (option) => option.value === (filterValue.requiredness ? ClientFormUserRoleRequiredness.required : ClientFormUserRoleRequiredness.optional),
      ) || requirednessOptions[0]
    );
  }, [filterValue?.requiredness, requirednessOptions]);

  const showRequirednessFilter = useMemo(
    () =>
      columnConfig.type === ColumnType.MetaData &&
      [tableViewMetadata.approver as string, tableViewMetadata.reviewer as string].includes(columnConfig.value),
    [columnConfig.type, columnConfig.value],
  );

  const where = useMemo(() => {
    const all = {
      role: {
        in: [
          PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Owner],
          PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Validator],
          PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Approver],
          PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Contributor],
          PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Viewer],
        ],
      },
    };

    if (columnConfig.type === ColumnType.MetaData) {
      switch (columnConfig.value) {
        case tableViewMetadata.members:
          return all;
        case tableViewMetadata.owner:
          return { role: { in: [PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Owner]] } };
        case tableViewMetadata.editor:
          return { role: { in: [PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Contributor]] } };
        case tableViewMetadata.approver:
          return { role: { in: [PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Approver]] } };
        case tableViewMetadata.reviewer:
          return { role: { in: [PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Validator]] } };
        case tableViewMetadata.viewer:
          return { role: { in: [PlatformClientFormUserRoleStatusToGQLStatus[ClientFormUserRole.Viewer]] } };
        default:
          return all;
      }
    }

    return all;
  }, [columnConfig.type, columnConfig.value]);

  const { loading: isFetching, data: clientUsers } = useQuery(ClientFormUsersQuery, {
    variables: {
      cmId: clientModuleId,
      cmsId: clientModuleSectionId,
      tfId: selectedTemplateFormId === allTemplatesId ? undefined : selectedTemplateFormId,
      where: where,
    },
  });

  const uniqueUsers = useMemo(() => {
    const data = clientUsers?.clientFormUsers ?? [];

    // Use a Set to ensure uniqueness by userId
    const uniqueUsersMap = new Map<string, (typeof data)[number]>();

    data.forEach((user) => {
      if (user.pbkUser?.id) {
        uniqueUsersMap.set(user.pbkUser.id as string, user); // Use Map to overwrite duplicates
      }
    });

    return Array.from(uniqueUsersMap.values());
  }, [clientUsers?.clientFormUsers]);

  const sortedUsers = useMemo(() => {
    if (isFetching) return [];

    // Create a mapping for requiresAction status for each user
    const requiresActionMapping: Record<string, boolean> = {};
    const users = uniqueUsers
      .slice() // Create a shallow copy before sorting to avoid mutating the original array
      .sort((a, b) => ((a.pbkUser?.fullName ?? '') > (b.pbkUser?.fullName ?? '') ? 1 : -1))
      .map((user) => {
        requiresActionMapping[user.pbkUser?.id as string] = !!user.requiresAction;

        return {
          id: `${user.pbkUser?.id}`,
          text: (user.pbkUser?.fullName ? user.pbkUser?.fullName : user.pbkUser?.email) || '',
          value: hasPermission(Roles.TeamMember) ? false : currentUser?.id === user.pbkUser?.id,
          userImageId: user.pbkUser?.userImageId,
        } as UserOption;
      });

    setRequiresActionMap(requiresActionMapping);
    return users;
  }, [isFetching, uniqueUsers, hasPermission, currentUser?.id]);

  useEffect(() => {
    setFilteredUsers(sortedUsers);
  }, [sortedUsers]);

  const handleSearch = useCallback(
    (term: string) => {
      setFilteredUsers(
        uniqueUsers
          .filter(({ pbkUser }) => `${pbkUser?.fullName ?? ''} ${pbkUser?.email ?? ''}`.toLowerCase().includes(term.toLowerCase()))
          .map((user) => ({
            id: `${user.pbkUser?.id}`,
            text: (user.pbkUser?.fullName ? user.pbkUser.fullName : user.pbkUser?.email) || '',
            value: hasPermission(Roles.TeamMember) ? false : currentUser?.id === user.pbkUser?.id,
            userImageId: user.pbkUser?.userImageId as string,
          })),
      );
    },
    [uniqueUsers, hasPermission, currentUser?.id],
  );

  const onSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchPhrase = event.target.value;
    setSearchTerm(searchPhrase);
    handleSearch(searchPhrase);
  };

  const updateFilter = (selectedMember: Option<string, boolean>) => {
    const isMemberSelected = filterValue?.users.includes(selectedMember.id);

    const updatedUsers = isMemberSelected
      ? filterValue?.users.filter((id) => id !== selectedMember.id)
      : [...(filterValue?.users || []), selectedMember.id];

    const updatedRequiredness =
      showRequirednessFilter && selectedRequiredness.value !== ClientFormUserRoleRequiredness.any
        ? selectedRequiredness.value === ClientFormUserRoleRequiredness.required
        : null;

    const updatedFilter: MembersFilterValue = {
      requiredness: updatedRequiredness,
      users: updatedUsers || [],
    };

    setFilters(columnConfig, selectedTemplateId, updatedUsers?.length ? updatedFilter : undefined);
  };

  const isSelected = (optionId: string) => filterValue?.users?.includes(optionId) ?? false;

  const toggleSelectAll = useCallback(
    (selectAll: boolean) => {
      const updatedUsers = selectAll ? filteredUsers.map((option) => option.id) : [];

      const updatedFilter: MembersFilterValue = {
        requiredness: filterValue?.requiredness ?? null,
        users: updatedUsers,
      };

      setFilters(columnConfig, selectedTemplateId, updatedUsers.length > 0 ? updatedFilter : undefined);
    },
    [filteredUsers, setFilters, columnConfig, selectedTemplateId, filterValue],
  );

  useEffect(() => {
    // Restore filtered users and selected requiredness when the component is mounted or reopened
    const initialFilteredUsers =
      filterValue && filterValue.requiredness !== null
        ? sortedUsers.filter((user) =>
            filterValue.requiredness === true ? requiresActionMap[user.id] === true : requiresActionMap[user.id] === false,
          )
        : sortedUsers; // When filterValue is undefined, show all users

    setFilteredUsers(initialFilteredUsers);
  }, [filterValue, sortedUsers, requiresActionMap]);

  const onRequirednessChange = useCallback(
    (requirednessOption: RequirednessOption) => {
      const updatedRequiredness =
        requirednessOption.value !== ClientFormUserRoleRequiredness.any ? requirednessOption.value === ClientFormUserRoleRequiredness.required : null;

      const filteredUsersList =
        requirednessOption.value === ClientFormUserRoleRequiredness.any
          ? sortedUsers
          : sortedUsers.filter((user) =>
              requirednessOption.value === ClientFormUserRoleRequiredness.required
                ? requiresActionMap[user.id] === true
                : requiresActionMap[user.id] === false,
            );

      setFilteredUsers(filteredUsersList);

      const updatedFilter: MembersFilterValue = {
        requiredness: updatedRequiredness,
        users: filterValue?.users || [],
      };

      setFilters(columnConfig, selectedTemplateId, updatedFilter.users.length > 0 || updatedFilter.requiredness !== null ? updatedFilter : undefined);
    },
    [sortedUsers, requiresActionMap, filterValue?.users, setFilters, columnConfig, selectedTemplateId],
  );

  const emptyMessage = useMemo(() => {
    if (searchTerm.length > 0) {
      return t('table-view:filters.members.no-data.search-description');
    }
    if (selectedRequiredness.value === ClientFormUserRoleRequiredness.optional) {
      return t('table-view:filters.members.no-data.optional-description');
    }
    if (selectedRequiredness.value === ClientFormUserRoleRequiredness.required) {
      return t('table-view:filters.members.no-data.required-description');
    }
    return t('table-view:filters.members.no-data.default-description');
  }, [searchTerm.length, selectedRequiredness.value, t]);

  return (
    <div>
      <SkeletonLoader ready={!isFetching} type="blockRow" rows={6}>
        <div className="-mx-2 my-2">
          <SearchInput style={InputStyle.MINIMAL} value={searchTerm} onChange={onSearchTextChange} placeholder={t('search-members-placeholder')} />
        </div>
        {showRequirednessFilter && (
          <div className="relative -mx-2 my-2 flex items-center justify-end">
            <DropdownSelect
              options={requirednessOptions}
              onChange={onRequirednessChange}
              size={DropdownSize.S}
              value={selectedRequiredness}
              wrapperClassName="min-w-1/3"
              isFixed={false}
            />
          </div>
        )}
        <div className="text-dpm-14 my-1 flex flex-row justify-between font-medium">
          {filteredUsers.length > 0 && (
            <>
              <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(() => toggleSelectAll(true))} data-cy="select-all">
                {t('common:list.filter.select-all')}
              </span>

              <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(() => toggleSelectAll(false))} data-cy="clear-all">
                {t('common:list.filter.clear-all')}
              </span>
            </>
          )}
        </div>
        <div className="-mr-4 h-64 w-72 overflow-y-auto">
          {filteredUsers.map((option) => (
            <div key={option.id} className="flex items-center gap-2">
              <Checkbox value={isSelected(option.id)} onChange={() => updateFilter(option)} role="menuitem" />
              <UserListRenderer id={option.id} text={option.text} userImageId={option.userImageId} value={PeopleType.Member} size={ImageSize.XS} />
            </div>
          ))}
          {!filteredUsers.length && (
            <div className="flex h-full items-center justify-center">
              <div className="flex flex-col items-center">
                <UsersGroup2Icon className="bg-gray-6 text-gray-1 h-16 w-16 rounded-[8px] p-3" />
                <div className="text-dpm-20 text-center font-medium">{t('table-view:filters.members.no-data.title')}</div>
                <div className="text-dpm-14 text-center font-normal">{emptyMessage}</div>
              </div>
            </div>
          )}
        </div>
      </SkeletonLoader>
    </div>
  );
};

export default MembersFilter;
